@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .react-grid-item.react-grid-placeholder {
      @apply !bg-surface-interact border-2 border-dashed border-surface-accent transition-all duration-200 ease-in-out;
    }
}

.mdxeditor {
    @apply text-content font-sans;
    h1 {
        @apply text-display-md font-semibold;
    }
    h2 {
        @apply text-display-sm font-semibold;
    }
    h3 {
        @apply text-display-xs font-semibold;
    }
    p {
        @apply text-md font-normal;
    }
    a {
        @apply text-md font-normal text-primary underline hover:text-primary-highlight cursor-pointer;
    }
}